import * as Sentry from '@sentry/react'

import { AxiosResponse } from 'axios'
import { isProdStand, SENTRY_DSN } from '~/shared/config/env'

const excludePath = ['/assets/', '/static/', '/media/', 'node_modules']

const excludeMessages = [
  'Network Error',
  'Token has expired and can no longer be refreshed',
  'Unauthenticated',
  'This action is unauthorized',
]

const excludeStatusCodes = [422]

export const initSentry = () => {
  if (!isProdStand || !SENTRY_DSN) return

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.extraErrorDataIntegration({ depth: 5 }),
    ],

    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    beforeSend: (event, hint) => {
      const originalException = hint?.originalException as {
        response: AxiosResponse | undefined
        message: string
      }

      const message = originalException.message
      if (message && excludeMessages.some((msg) => message.includes(msg))) {
        return null
      }

      const status = event?.extra?.status
      if (
        status &&
        excludeStatusCodes.some((excludeStatus) => excludeStatus === status)
      ) {
        return null
      }

      const url = originalException?.response?.config?.url as string

      if (url && excludePath.some((path) => url.includes(`${path}`))) {
        return null
      }

      return event
    },
  })
}
